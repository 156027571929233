.dropdown_menu {
  width: 217px !important;
  margin-right: 10px!important;
}

.calender_class{
 max-width: 60% !important;
  width: 60% !important;
}
.calender_class .react-date-picker__inputGroup {
  background-color: transparent ;
}
.calender_class .react-date-picker__clear-button {
  display: none !important;
}

.placeholder_padded {
  padding: 5px;
  border-radius: 5px;
 width: 60% !important;
}
.placeholder_padded::placeholder {
  padding: 5px; /* Adjust the padding value as needed */
}

.hide-scrollbar {
  max-width: 90px;
  overflow-x: auto;
  -ms-overflow-style: none; 
  scrollbar-width: none; 
  -webkit-overflow-scrolling: touch; 
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Webkit (Chrome, Safari, etc.) */
}

.btn-check:checked + .btn.btn-danger, .btn-check:active + .btn.btn-danger, .btn.btn-danger:focus:not(.btn-active), .btn.btn-danger:hover:not(.btn-active), .btn.btn-danger:active:not(.btn-active), .btn.btn-danger.active, .btn.btn-danger.show, .show > .btn.btn-danger{
  background-color:  #FC99A3  !important;
  color: black !important;
}


.btn.btn-danger.dropdown-toggle:after{
  color: black !important;

}