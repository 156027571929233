.board_container {
  position: fixed;
  top: 65px;
  padding-right: 45px;
  padding-left: 38px;
  padding-top: 15px;
  padding-bottom: 15px;
  opacity: 1;
  background: #f5f8fa;
  width: 87%;
  margin-left: -30px;
  z-index: 1;
  border-bottom: 1px solid #ddd;
 
}

.header-name-elipse {
  width: 100%;
  /* max-width: 200px; */
  overflow: hidden;
  /* text-wrap: nowrap; */
  text-overflow: ellipsis;
  white-space: nowrap;
}

.react-switch {
  vertical-align: bottom;
  margin-left: 4px;
}

.board_header_heading{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
 
}


.board_header_button{
 display: flex;
 justify-content: space-between;
 align-items: center;
 gap : 20px;

 
}
.board_header_button_align{
  justify-content: space-between;
 
}



.switch_btn{
  margin-inline: 16px;
}

.sort_and_export_wrapper{
    display: flex;
    align-items: center;
}

.mobile_menu_dropdown{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
}
/* .sort-label{
  display: inline;
} */
@media (min-width: 800px )  and (max-width:1280px) {
  .sort-label{
    display: none !important;
  }
  
}
@media (min-width: 900px ) {
  .board_container_verify{
    top:96px
  }
  
}
@media (max-width: 600px ) {
  .board_container_verify{
   padding-top: 25px;
  }
  
}
@media (min-width: 768px ) and (max-width: 900px) {
  .board_container {
    width: 100%;
    padding-left:0px;
    margin-left: 10px;
   top: 80px;
   /* padding-top: 0; */
  }


}


.mobile_dropdown_sort_export_btn{
  display: none;
}

.design_and_template_btn{
  display: flex;
  /* align-items: center; */
  flex-direction: row;
}
@media (max-width: 768px) {
  .switch_btn{
    margin-inline: 0px;
  }
  .sort_and_export_wrapper{
       display: none;
  }
  .mobile_dropdown_sort_export_btn{
    display: block;
    
  }
  .design_and_template_btn{
   
     display: none;
  }

  .board_header_button {
    justify-content: space-between;
  }
  .board_container {
    margin-left: 10px;
    width: 100%;
    padding-left: 10px;
    left: 0;
  }
}











/* @media (max-width: 768px) {
  .board_header_button {
    flex-direction: column !important;
  }
  .switch_btn{
   
    margin-inline: 0px;
  }
  .sort_and_export_wrapper{
       margin-top: 20px;
  }
  .board_container{
    margin-left : 0px;
    padding-bottom: 0px;
    padding-top: 0px;

  }
} */




