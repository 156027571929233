.scrollable-div {
    max-height: 500px;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none; /* Hide the scrollbar in Firefox */
    -ms-overflow-style: none; /* Hide the scrollbar in IE/Edge */
  }
  
  /* Hide the scrollbar in WebKit browsers like Chrome and Safari */
  .scrollable-div::-webkit-scrollbar {
    width: 0;
    display: none;
  }
  
  /* You can add additional styling to the scrollbar thumb and track for WebKit if needed */
  .scrollable-div::-webkit-scrollbar-thumb {
    background: transparent;
  }
  
  .scrollable-div::-webkit-scrollbar-track {
    background: transparent;
  }
  
  .nav-tabs .nav-link {
    color: #ccc !important; 
  
  }
  
  .nav-tabs .nav-link.active {
    color: #007bff !important; /* Default color for inactive tabs (blue) */
  
    
  }