.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
   
  }
  
  .modal-content {
    background-color: #fff;
    border-radius: 0px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    overflow: auto;
    max-width: 100%;
    max-height: 100%;
  }
  
 .modal-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 10px;
  }
  
  .modal-title {
    margin: 0;
    font-weight: 500 !important;
    font-size: 1.5rem !important;
  }
  
  .modal-image {
    width: 90%;
    height: 90%;
    object-fit: contain;
  }
  
  .modal-icon-custom{
   cursor: pointer !important;
   font-size: 1.5rem !important;
   font-weight: bold !important;
  }