.general-search-small-screen {
    display: none !important;
    /* margin-bottom: 20px; */
  }
  .general-search-large-screen {
    display: block !important;
  }
  
  @media (min-width: 500px) and (max-width: 768px) {
    .search-input-wrapper-mobile {
      max-width: 350px;
    }
  
    .general-search-small-screen {
      display: block !important;
    }
    .general-search-large-screen {
      display: none !important;
    }
  }
  @media (min-width: 200px) and (max-width: 500px) {
    .search-input-wrapper-mobile {
      max-width: 300px;
    }
  
    .general-search-small-screen {
      display: block !important;
    }
    .general-search-large-screen {
      display: none !important;
    }
  }
  