//
// Content
//

// Desktop mode
@include media-breakpoint-up(lg) {
  .content {
    padding: get($content-spacing, desktop) 0;

    // Toolbar enabled and toolbar not fixed mode
    .toolbar-enabled:not(.toolbar-fixed) & {
      padding-top: 0;
    }
  }
  .content_verify{
    padding: 135px 0 !important
  }
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
  .content {
    padding: get($content-spacing, tablet-and-mobile) 0;

    // Toolbar enabled mode and toolbar not fixed mode
    .toolbar-enabled:not(.toolbar-tablet-and-mobile-fixed) & {
      padding-top: 0;
    }
  }
  .content_verify{
    padding: 105px 0 !important
  }
}
@media (min-width: 991px) {
  .content {
      padding: 115px 0;
  }
}
@media (max-width: 991px) {
  .content {
      padding: 80px 0;
  }
}
