.btn-custom-secondary{
    background-color: #e4e6ef;
  }
  .btn-custom{
    border: 0;
    border-radius: 5px;
  }
  .btn-custom:focus-visible{
    outline: 0;
  }
  .bi{
    color: black;
  }