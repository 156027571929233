.col-btn-container {
  width: 250px;
}

.col-btn-collapsible {
  color: white;
  cursor: pointer;
  /* padding: 18px; */
  width: 100%;
  border: none;
  /* background-color: rgb(95, 104, 104); */
  /* font-size: 15px; */
}

.active,
.col-btn-collapsible:hover {
  background-color: rgb(93, 177, 255);
}

.col-btn-content {
  padding: 10px;
  overflow: hidden;
  /* border: 1px solid black; */
  background-color: #cccccccf;
}
