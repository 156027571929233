
.pricing-heading, .contact-heading{
  margin-top: 8%;
}

.price{
  font-size: 50px;
  font-weight: 600;
}
.price-duration{
  background: #B084CF;
  color:white;
  font-size:14px;
}
.pricing-card{
  border: 1.5px solid #1F7B78;
  border-radius: 28px;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}
.pricing-card:hover {
    transform: scale(1.06); /* Apply zoom effect on hover */
    cursor: pointer;
}
.pricing-card.active {
    transform: scale(1.05); /* Apply zoom effect on hover */
    background-color: rgb(180, 221, 255);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Add a box shadow effect for the active state */
    cursor: pointer;
  }


.single-services .services-content .services-title a, .pricing-content .pricing-title a {
  font-size: 26px;
  font-weight: 700;
  color: #2e2e2e;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-services .services-content .services-title a, .pricing-content .pricing-title a {
    font-size: 24px;
  }
}

@media (max-width: 767px) {
  .single-services .services-content .services-title a, .pricing-content .pricing-title a {
    font-size: 20px;
  }
}

.single-services .services-content .services-title a:hover , .pricing-content .pricing-title a {
  color: #009ef7;
}

 