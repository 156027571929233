

.time-wrapper {
  width: 100%;
 
}

.time-Picker-search {
  width: 212px !important;
  max-width: 212px !important;
  padding: 0px !important;
  border: 1px solid gray !important;
}
.time-Picker-search .date-filter {
  width: 100% !important;
}

.home-search-card-date .react-date-picker__inputGroup {
  background-color: #fff !important;
}

.notification-circle {
  position: absolute;
  top: -5px; /* Adjust based on your design */
  right: -5px; /* Adjust based on your design */
  background-color: red;
  color: white;
  font-size: 10px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

