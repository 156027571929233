.load_template_selector{
    width: 250px;
    margin-left: 10px;
}

@media (max-width: 740px) {
    .load_template_selector{
        width: 150px;
        margin-left: 0px;
    }
}