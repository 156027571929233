.css-input {
  font-size: 16px;
  border-color: #cccccc;
  border-style: solid;
  border-width: 0px;
  border-radius: 6px;
  padding: 0.3em 2px;
}
.css-input:focus {
  outline: none;
}

.italics-placeholder::placeholder {
  font-style: italic;
  /* Add more styling properties if needed */
}