.circle-cards {
  display: inline-block;
  background-color: #009ef7;
  margin:  -3px -5px;

  border-radius: 50%;
}

.btn-comments{
  padding:9px
}

.file-upload{
  min-width: 0px !important;
  max-width: 676px !important;
  margin-block: 5px !important
}

/* .circle-inner {
    color: white;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    height: 100px;
    width: 100px;
    font-size: 30px;
  } */

.circle-inner {
  color: white;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  height: 40px;
  width: 40px;
  font-size: 14px;
  padding: 25% 6%;
}

.image-resize img {
  max-width: 250px !important;
  max-height: 250px !important;
 
  object-fit: contain !important;
}

.text-editor .ql-editor {
  min-height: 160px;
  max-height: 160vh;
}


@media (max-width: 1300px) and (min-width: 500px) {
  .circle-cards  {
    margin: -7px !important;
  }
}
@media (max-width:500px) and (min-width: 200px) {
  .circle-cards  {
    margin: -15px !important;
  }
}