.word-break-custom-class{
  word-break: break-word;

}
.custom-class1{
    background-color: 'rgb(235; 235; 235)';
    width: '310px';
    height: '54vh ';
    overflow-y: 'auto';
    padding: '0';
    word-break: break-all;
   
}
@media (max-width: 768px) {     
  .custom-class1{
    height: 52vh !important  ;
  }

}
@media (min-width: 992px){     
  .custom-class1{
    height: 65vh !important  ;
  }

}
@media (min-width: 1200px){     
  .custom-class1{
    height: 54vh !important  ;
  }

}
@media (min-width: 1517px){     
  .custom-class1{
    height: 52vh !important  ;
  }

}
@media (min-width: 1707px){     
  .custom-class1{
    height: 62vh !important  ;
  }

}
@media (min-width: 1821px){     
  .custom-class1{
    height: 60vh !important  ;
  }

}
@media (min-width:2049px){     
  .custom-class1{
    height: 68vh !important  ;
  }

}
@media (min-width:2732px){     
  .custom-class1{
    height: 76vh !important  ;
  }

}
.custom-class2{
    background-color: 'rgb(235; 235; 235)';
    width: '310px';
    height: '56vh ';
    overflow-y: 'auto';
    padding: '0';
    word-break: break-all;
   
}
@media (max-width: 768px) {     
  .custom-class2{
    height: 54vh !important  ;
  }

}
@media (min-width: 992px){     
  .custom-class2{
    height: 67vh !important  ;
  }

}
@media (min-width: 1200px){     
  .custom-class2{
    height: 59vh !important  ;
  }

}
@media (min-width: 1517px){     
  .custom-class2{
    height: 63vh !important  ;
  }

}
@media (min-width: 1707px){     
  .custom-class2{
    height: 67vh !important  ;
  }

}
@media (min-width: 1821px){     
  .custom-class2{
    height: 69vh !important  ;
  }

}
@media (min-width:2049px){     
  .custom-class2{
    height: 72vh !important  ;
  }

}
@media (min-width:2732px){     
  .custom-class2{
    height: 78vh !important  ;
  }

}