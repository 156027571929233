.custom_searchbar {
  border-radius: 0.475rem 0rem 0rem 0.475rem !important;
}
.close_icon {
  position:absolute;
  right: 50px;
  bottom: 0;
  background: #c6c6c6;
  border-radius: 50%;
  z-index: 10;
  color: white;
}
.custom_input_group{
  background: #eef3f7;
   border: 0px solid;
   border-radius: 0rem 0.475rem 0.475rem 0rem !important
}
.top_custom_search{
  border: 0.5px solid #959595 !important;
}
