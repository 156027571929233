.circle-cards {
  display: inline-block;
  background-color: #009ef7;
  margin: -3px -5px;

  border-radius: 50%;
}

.text-editor .ql-editor {
  min-height: 160px;
  max-height: 60vh;
  overflow-y: auto;
  cursor: default;
}

div.ql-editor a.custom-link {
  cursor: pointer !important;
}

.ql-editor strong {
  font-weight: bold;
}

.pattern-class {
  padding: 5px;
  cursor: pointer !important;
}

.custom-card {
  max-width: 300px;
  width: 100%;
  max-height: 80px;
  height: 80px;
  overflow: hidden;
  padding-bottom: 5px;
}

.custom-card-description {
  max-width: 300px;
  max-height: 80px;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  font-size: 14px;
  margin: 0;
}

.image_resize_textEditor img {
  max-width: 250px !important;
  max-height: 250px !important;
  object-fit: contain !important;
  /* cursor: pointer; */
}

.ql-mention-list-item {
  text-wrap: nowrap;
  list-style-type: none;
  cursor: pointer;
  /* max-width: 300px;
  width: 300px; */
}
.ql-mention-list-container {
  width: 300px !important;
  max-height: 200px !important;
  border-radius: 5px !important;
}
.ql-mention-list {
  overflow: auto;
  list-style-type: none;
}

#quill-mention-list {
  /* width: fit-content !important; */
}
.ql-mention-list-item.selected {
  background-color: #ffffff;
  color: #009ef7;
  /* width: fit-content; */
}
.mention {
  background-color: transparent;
  color: #0067c5;
  cursor: pointer;
  text-decoration: underline;
}
.theme__div{ 
  .mention {
    color: #98C6F9;
  }
}

@media (max-width: 1300px) and (min-width: 500px) {
  .circle-cards {
    margin: -7px !important;
  }
}
@media (max-width: 500px) and (min-width: 200px) {
  .circle-cards {
    margin: -17px !important;
  }
}

