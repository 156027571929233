.custom {
  right: 45px !important;
  bottom: 17px !important;
  position: absolute;
  inset: 0px 0px auto auto;
  transform: translate3d(0.8px, 29.6px, 0px) !important;
}

.ellipsis-template-name {
  width: 150px;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.template-image {
  height: 60vh;
  

  pointer-events: none;
}

.pagination-template {
  position: absolute;
  bottom: 10px;
  width: 100%;
  background: white;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 15px !important;
  padding-bottom: 5px !important;
  padding-right: 10px !important;
 
}


.template-desc{
  width: 800px !important;
}

@media (max-width: 768px) {
  .tempalte-table-row {
    height: 60px;
    max-height: 60px !important;
  }
  .template-description {
    height: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    width: 100% !important;
  }
  .pagination-template {
    bottom: 10px
    
   
  }
  .template-desc{
    width: 400px !important;
  }
}


