.tooltip-container {
    position: relative;
    display: inline-block;
}

.tooltiptext {
    visibility: visible;
    width: max-content;
    background-color: #D1F684; 
    color: black;
    text-align: start;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ddd; /* Optional: Adds a light border for distinction */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    position: absolute;
    z-index: 1;
    /* bottom: 125%; Position the tooltip above the icon */
    left: 50%;
    /* transform: translateX(-50%); */
    opacity: 0;
    transition: opacity 0.3s;
    max-width: 270px;
    max-height: 400px;
    overflow-y:auto ;
    top: 25%;
}

.tooltip-container:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}
.tooltiptext head{
    color: #242343;
}
.tooltiptext headings{
    color: #3C3C3C;
}
