.datePicker input {
  padding: 10px 5px;
  border-radius: 4px;
  border: 1px solid gray;
  width: 102%;
}

.datePicker-small input {
  padding: 4px 4px;
  border-radius: 4px;
  border: 1px solid gray;
  width: 100%;
}

.react-date-picker__inputGroup {
  background-color: #fc99a3;
}

.react-datepicker-wrapper {
  margin-right: 10px !important;
  display: block;
}
.react_date_dark input {
  background-color: rgb(57,73,89) !important;
  color: white;
}
.react_date_dark {
  .react-datepicker__time,
  .react-datepicker__header,
  .react-datepicker__month-container,
  .react-datepicker__day-name,
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker__day,
  .calender_class,
  .calender_class .react-date-picker__inputGroup  {
    background-color: #1d1e2c;
    color: white !important;
  }

  .react-datepicker__week {
    color: white !important;
  }
  .react-datepicker__time-list-item:hover{
    color: #1d1e2c;
  }
  .react-datepicker__day:hover{
    background-color: white !important;
    color: #1d1e2c !important;
  }
  .react-datepicker__day:hover{
    background-color: white ;
    color: #1d1e2c ;
  }
  .react-datepicker__day--selected{
    background-color: #216ba5;
  }
  .react-date-picker__calendar-button{
    background-color: white;
  }
}
.react_date_dark{
  .react-date-picker__clear-button{
    background-color: white;
  }
  .react-date-picker__calendar-button{
    background-color: white;
  }
  .react-daterange-picker__clear-button{
    background-color: white;
  }
  .react-daterange-picker__calendar-button{
    background-color: white;
  }
  /* .react-date-picker__clear-button__icon react-date-picker__button__icon{
    background-color: white;
  }
  .react-date-picker__calendar-button__icon react-date-picker__button__icon{
    background-color: white;
  } */
}
.react_date_picker_dark .react-calendar .react-calendar__tile--now{
  background-color: lightgreen !important;
  color: black !important;
}