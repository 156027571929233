.modal-height {
  height: 90vh !important;
}
#pdf-controls {
  display: none;
}

#no-renderer-download {
  display: none !important;
}

#WACStatusBarContainer {
  display: none;
}
.WACStatusBarContainer {
  display: none;
}

#m_excelEmbedRenderer_m_ewaEmbedViewerBar {
  display: none !important;
}

.kiIQVn {
  overflow-y: visible !important;
}

.custom-list-home {
  position: absolute;
  top: 40px;
  left: 0;
  z-index: 1000; /* Ensure the list appears above other elements */
  

  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  max-height: 200px; /* Limit the height if needed */
  overflow-y: auto; /* Enable scrolling if the list exceeds max-height */
}

.home-searching-modal-body {
  
  max-height: 100vh !important;
}

.custom-list-home li {
  padding: 0.5rem;
  cursor: pointer;
}



.border-right {
  border-right: 1px solid #e6e6e6;
}

@media screen and (max-width: 940px) {
  .border-right {
    border-right: none;
  }
}
@media screen and (max-width: 500px) {
 
  .home-searching-modal-body {
    max-height: 650px !important;
  }
  
}

.left-section-list {
  margin-block: 5px !important;
  margin-inline: 5px !important;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.description {
  width: 50px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header-image {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  transition: background-color 0.2s ease;
}

.header-image:hover {
  background-color: var(--color-G50);
}

.title {
  padding: 5px;
  transition: background-color 0.2s ease;
  flex-grow: 1;
  user-select: none;
  position: relative;
}

.title:focus {
  outline: 2px solid #e3fcef;
  outline-offset: 2px;
}

.template-image {
  height: 60vh;
 

  pointer-events: none;
}

.visit {
  font-size: 16px;
  cursor: pointer;
}
.visit:hover {
  opacity: 0.5;
}

.ellipsis-text {
  width: 120px;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.custom-list {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000; /* Ensure the list appears above other elements */
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  max-height: 200px; /* Limit the height if needed */
  overflow-y: auto; /* Enable scrolling if the list exceeds max-height */
}

.custom-list li {
  padding: 0.5rem;
  cursor: pointer;
}

.custom-list li:hover {
  background-color: #f8f9fa;
}

.date-filter {
  padding: 5px !important;
  border: none !important;
}

.datePickerSearch {
  border: 1px solid rgb(235, 222, 222) !important;
}

.right-message-contianer {
  opacity: 0.2;
  height: 100%;
}

.icon {
  font-size: 87px;
}

.tab {
  font-size: 33px;
  font-weight: 700;
}

.search-dropdown {
  padding: 0px;
  position: absolute;
  top: 50px;
  right: 0px;
}
.dropdown-toggle-btn {
  margin-bottom: 10px;
  cursor: pointer;
}

.left-section {
  height: 100% !important;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 0.5rem;
  z-index: 10000;
}

.right-section {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100% !important;
}

.left-section::-webkit-scrollbar-thumb {
  display: none !important;
}

.left-section::-webkit-scrollbar-track {
  background: transparent !important;
}
.right-section::-webkit-scrollbar-thumb {
  display: none !important;
}
.right-section::-webkit-scrollbar-track {
  background: transparent !important;
}
