.main-pagination .page-item:last-child .page-link{
    font-size: 45px;
}
.main-pagination .page-item:first-child .page-link{
    font-size: 45px;
}

.main-pagination .page-item{
    font-size: 45px !important
}

.main-pagination .page-link {
    border-radius: 50% !important;
   
}

.main-pagination .active {
    border-radius: 50% !important;
    color : #ffff !important
}

.main-pagination .page-item.active .page-link{
    color : #ffff !important

}