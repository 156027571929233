.alert-btn {
  display: flex !important;
  justify-content: flex-end;
}

.file-upload{
    min-width: 0px !important;
    max-width: 676px !important;
    margin-block: 5px !important
}
@media screen and (max-width: 450px) {
  .alert-btn {
    display: flex !important;

    justify-content: flex-end;
  }
}

@media (max-width: 700px) {
    .alert-btn {
        margin-top: 5px;
      }
}
@media (max-width: 550px) {
    .link-span{
        display: block !important;
    }
    .responsive-font{
        font-size: 14px;
    }
    .goto-board-btn{
        display: none;
    }
}

.link-span {
    color: #27aff2;
    display: none;
    
  }
  
  .link-span:hover {
    color: #27aff2;
    text-decoration: underline;
    cursor: pointer;
    opacity: 0.6;
  }
    
.modal-backdrop.show {
    position: fixed; 
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    background: #5E6D7E;
    z-index: 1040; 
}
  
.theme__div{
    ::placeholder {
    color: #98C6F0 !important;
  }
}
.label-color{
  color: #98C6F0 !important;
}
