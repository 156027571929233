.alter_email_verification_mobile{
    display: none;
    margin: 0px !important;
    padding-right: 2px !important;
    font-size: 10px;
    
}
.resend_link {
    cursor: pointer;
    font-weight: 600;
    border-bottom: 2px solid;
    padding-inline: 5px; /* Use padding-inline for inline padding (left and right) */
  }
  
.alter_email_verification_tablet{
    display: none;
    margin: 0px !important;
    padding-right: 2px !important;
   
}

.alter_email_verification_desktop{
    /* display: block; */
    margin: 0px !important;
    /* padding-right: 2px !important;
    font-size: 11px; */
   
}
@media (min-width: 741px) and (max-width: 1024px) {
    .alter_email_verification_mobile {
      display: none;
    }
    .alter_email_verification_tablet {
      display: inline-block;
    }
    .alter_email_verification_desktop {
      display: none;
    }
}
@media (max-width: 740px) {
    .alter_email_verification_mobile{
        display: inline-block;
    }
    .alter_email_verification_tablet{
        display: none;
     }
     
    .alter_email_verification_desktop{
       display: none;
    }
    
    
    
  }
